<template>
    <v-card 
      class="mx-auto my-8 pa-4"
      max-width="344"
      elevation="16"
    >
      <v-card-title style="text-align: center;">Login<br />Dentalteknik Labels</v-card-title>
      <v-card-item>
        <v-form @submit.prevent="login">
          <v-text-field
            prepend-inner-icon="mdi-account"
            v-model="username"
            label="Brugernavn"
            variant="solo"
          ></v-text-field>
          <v-text-field 
            prepend-inner-icon="mdi-lock-outline"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            v-model="password"
            label="Kodeord"
            variant="solo"
            @click:append-inner="visible = !visible"
          ></v-text-field>
          <v-btn type="submit" color="primary" block class="mt-2">Login</v-btn>
        </v-form>
      </v-card-item>
    </v-card>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '../router';
  
  
  export default {
    setup() {
      const username = ref('');
      const password = ref('');
      const store = useStore();
      const visible = ref(false);

      const login = async () => {
        try {
          const response = await store.dispatch('auth/login', { username: username.value, password: password.value });
          console.log('Login successful', response);
          // Redirect or handle success
          router.push({ name: 'dashboard' });
        } catch (error) {
          password.value = '';
          username.value = '';
          
          console.error('Login failed', error);
          // Handle error
        }
      };

      return { username, password, login, visible };
    },
  };
  </script>